import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
    {
        path: '/',
        component: () => import('@/views/home/index'),
        redirect: '/home',
        children: [
            {
                path: '/home',
                name: 'Home',
                component: () => import('@/views/home/Home')
            },
            {
                path: '/company',
                name: 'Company',
                component: () => import('@/views/home/Company'),
                
            },
            {
                path: '/school',
                name: 'School',
                component: () => import('@/views/home/School')
            },
            {
                path: '/government',
                name: 'Government',
                component: () => import('@/views/home/Government')
            },
            {
                path: '/consult',
                name: 'Consult',
                component: () => import('@/views/home/ConsultRoom'),
            },
            {
                path: '/interHospital',
                name: 'InterHospital',
                component: () => import('@/views/home/interHospital') 
            },
            {
                path: '/org',
                name: 'Org',
                component: () => import('@/views/home/Org')
            },
            {
                path: '/mechanism',
                name: 'Mechanism',
                component: () => import('@/views/home/Mechanism')
            }
        ]
    }, {
        path: '/login',
        name: 'Login',
        component: () => import('@/views/Login')
    }
]

const router = new VueRouter({
    scrollBehavior (to, from, savedPosition) {
        return { x: 0, y: 0 }
    },
    routes
})

export default router
